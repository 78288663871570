<template>
    <page
        id="sites-list"
        :title="translate('Site:::Sites', {}, 2)"
        :status="page_status"
        :waiting="waiting || loading"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ translate('Site:::Sites', {}, 2) }}
                <default-button
                    :title="translate('New Site')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="sites-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="!$mq.match(/^(md|lg|tl|xl)$/i) ? export_url : null"
            @sort="sort_by"
            @search="search"
            @clear-search="clear_search"
            @page="goto_page"
            @add="record_create(entity)"
            @click="record_edit(entity, $event.id)"
        >
            <template
                v-slot:controls
            >
                <div class="show-md">
                    <div class="columns">
                        <form-select
                            id="plan_id"
                            name="plan_id"
                            :value="state.plan_id"
                            :data-source="filterable_plans"
                            :required="false"
                            :empty-value="0"
                            :empty-label="translate('All Maint. Plans')"
                            class="column col-4 col-sm-12"
                            @input="search_by_plan"
                        />
                        <form-select
                            id="plan_repeat"
                            name="plan_repeat"
                            :value="state.plan_repeat"
                            :data-source="plan_repeats"
                            :required="false"
                            empty-value=""
                            :empty-label="translate('All Scopes')"
                            class="column col-4 col-sm-12"
                            @input="search_by_plan_repeat"
                        />
                        <form-select
                            id="host"
                            name="host"
                            :value="state.host_filter"
                            data-source="site"
                            :empty-value="0"
                            :empty-label="translate('All Hosts')"
                            :show-all="true"
                            :required="false"
                            class="column col-4 col-sm-12"
                            @input="filter_by_host"
                        />
                        <form-select
                            id="status"
                            name="status"
                            :value="state.filter"
                            :data-source="statuses"
                            :required="false"
                            :empty-value="null"
                            :empty-label="translate('Any Status')"
                            :class="{ 'mt-2': small_screen }"
                            class="column col-4 col-sm-12"
                            @input="filter('status', $event)"
                        />
                        <form-toggle-input
                            id="domain_managed"
                            name="domain_managed"
                            type="checkbox"
                            :value="state.domain_managed"
                            :required="false"
                            :class="{ 'mt-2': small_screen }"
                            class="column col-4 col-sm-12"
                            @input="filter_by_domain_managed"
                        >
                            {{ translate("Managed") }}
                        </form-toggle-input>
                    </div>
                </div>
                <div class="hide-md">
                    <form-select
                        id="plan_id"
                        name="plan_id"
                        :value="state.plan_id"
                        :data-source="filterable_plans"
                        :required="false"
                        :empty-value="0"
                        :empty-label="translate('All Maint. Plans')"
                        @input="search_by_plan"
                    />
                </div>
                <div class="hide-md">
                    <form-select
                        id="plan_repeat"
                        name="plan_repeat"
                        :value="state.plan_repeat"
                        :data-source="plan_repeats"
                        :required="false"
                        empty-value=""
                        :empty-label="translate('All Scopes')"
                        @input="search_by_plan_repeat"
                    />
                </div>
                <div class="hide-md">
                    <form-select
                        id="host"
                        name="host"
                        :value="state.host_filter"
                        data-source="site"
                        :empty-value="0"
                        :empty-label="translate('All Hosts')"
                        :show-all="true"
                        :required="false"
                        @input="filter_by_host"
                    />
                </div>
                <div class="hide-md">
                    <form-select
                        id="status"
                        name="status"
                        :value="state.filter"
                        :data-source="statuses"
                        :required="false"
                        :empty-value="null"
                        :empty-label="translate('Any Status')"
                        :class="{ 'mt-2': small_screen }"
                        @input="filter('status', $event)"
                    />
                </div>
                <div class="hide-md">
                    <form-toggle-input
                        id="domain_managed"
                        name="domain_managed"
                        type="checkbox"
                        :value="state.domain_managed"
                        :required="false"
                        :class="{ 'mt-2': small_screen }"
                        @input="filter_by_domain_managed"
                    >
                        {{ translate("Managed") }}
                    </form-toggle-input>
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <div class="text-gray text-small">
                    <a
                        :href="row.url"
                        target="_blank"
                        @click.stop
                    >
                        {{ row.domain }}
                    </a><span v-if="!!row.ip_address"> ({{ row.ip_address }})</span>
                </div>
                <div v-if="!!row.plan_id">
                    {{ plan_by_id(row.plan_id).name }}
                </div>
                <div v-if="!!row.plan_id" class="text-gray text-small">
                    {{ (row.computed_value + row.computed_license_revenue) | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                    |
                    {{ ((row.computed_value + row.computed_license_revenue) * 12) | nibnut.currency }}&nbsp;/&nbsp;{{ translate("year") }}
                </div>
                <div v-if="!!row.hosting_cost">
                    {{ translate("Host. Costs") }}: {{ row.hosting_cost | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                </div>
                <div v-if="!!row.computed_lifetime_value">
                    {{ translate("LTV") }}: {{ row.computed_lifetime_value | nibnut.currency }}
                </div>
                <monitor-check-status-cell
                    v-if="!!site_web_monitor(row).active"
                    :monitor="site_web_monitor(row)"
                    class="mt-2"
                />
                <monitor-check-status-cell
                    v-if="!!site_domain_monitor(row).active"
                    :monitor="site_domain_monitor(row)"
                    class="mt-2"
                />
                <monitor-check-status-cell
                    v-if="!!site_ssl_monitor(row).active"
                    :monitor="site_ssl_monitor(row)"
                    class="mt-2"
                />
                <monitor-check-status-cell
                    v-if="!!site_link_monitor(row).active"
                    :monitor="site_link_monitor(row)"
                    class="mt-2"
                />
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.name }}
                    <div class="text-gray text-small">
                        <a
                            :href="row.url"
                            target="_blank"
                            @click.stop
                        >
                            {{ row.domain }}
                        </a><span v-if="!!row.ip_address"> ({{ row.ip_address }})</span>
                    </div>
                </div>
                <div v-else-if="field === 'plan_id'">
                    <span v-if="!!row.plan_id">
                        {{ plan_by_id(row.plan_id).name }}
                    </span>
                    <div v-if="!!row.plan_id" class="text-gray text-small">
                        {{ (row.computed_value + row.computed_license_revenue) | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}
                        |
                        {{ ((row.computed_value + row.computed_license_revenue) * 12) | nibnut.currency }}&nbsp;/&nbsp;{{ translate("year") }}
                    </div>
                </div>
                <div v-else-if="field === 'license_ids'">
                    <router-link
                        v-for="license_id in row.license_ids"
                        :key="`row-${row.id}-${license_id}`"
                        :to="{ name: 'license.edit', params: { id: license_id } }"
                        class="mr-2"
                        @click.native.stop
                    >
                        {{ license_by_id(license_id).name }}
                    </router-link>
                </div>
                <span v-else-if="field === 'hosting_cost'">
                    <span v-if="!!row.hosting_cost">{{ row.hosting_cost | nibnut.currency }}&nbsp;/&nbsp;{{ translate("month") }}</span>
                </span>
                <span v-else-if="field === 'computed_lifetime_value'">
                    <span v-if="!!row.computed_lifetime_value" class="no-wrap">{{ row.computed_lifetime_value | nibnut.currency }}</span>
                </span>
                <monitor-check-status-cell
                    v-else-if="field === 'last_web_check'"
                    :monitor="site_web_monitor(row)"
                />
                <monitor-check-status-cell
                    v-else-if="field === 'last_domain_check'"
                    :monitor="site_domain_monitor(row)"
                />
                <monitor-check-status-cell
                    v-else-if="field === 'last_ssl_check'"
                    :monitor="site_ssl_monitor(row)"
                />
                <monitor-check-status-cell
                    v-else-if="field === 'last_links_check'"
                    :monitor="site_link_monitor(row)"
                />
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <new-site
            :show.sync="sampling"
        />
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_page, is_remote_data_table_source, misc_utilities, ui_utilities } from "@/nibnut/mixins"
import { is_sites_list_data_source } from "@/custom/mixins"

import {
    NewSite
} from "@/custom/dialogs"

import {
    FormSelect,
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

import {
    MonitorCheckStatusCell
} from "@/custom/components"

export default {
    name: "SiteList",
    mixins: [is_page, is_remote_data_table_source, misc_utilities, ui_utilities, is_sites_list_data_source],
    components: {
        FormSelect,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        NewSite,
        MonitorCheckStatusCell
    },
    methods: {
        clear_search () {
            this.clearing_search = true
            this.set_state_values({
                page: 1,
                filter_on: null,
                filter: null,
                host_filter: "",
                archived: false,
                plan_id: 0,
                plan_repeat: "",
                domain_managed: false,
                search: ""
            })
            this.refresh()
            setTimeout(() => {
                this.clearing_search = false
            }, 750)
        },
        filter_rows (rows) {
            if(this.state.filter_on && this.state.filter) {
                return rows.filter((row) => {
                    if(this.state.filter_on.match(/_at$/i)) return !!row[this.state.filter_on] === !!parseInt(this.state.filter)
                    if(this.state.filter_on === "status") {
                        if(this.state.filter === "errors") return row.computed_error_count > 0
                        if(this.state.filter === "warnings") return row.computed_warning_count > 0
                        if(this.state.filter === "broken-elements") return row.has_broken_elements
                        if(this.state.filter === "no-errors") return (row.computed_error_count === 0) && (row.computed_warning_count === 0)
                        if(this.state.filter === "orphans") return row.client_id === 0
                    }
                    return row[this.state.filter_on] === this.state.filter
                })
            }
            return rows
        },
        record_create (entity) {
            this.sampling = true
        },
        plan_by_id (plan_id) {
            return this.entity_record("plan", plan_id) || {}
        },
        search_by_plan (plan_id) {
            this.set_state_values({
                page: 1,
                plan_id
            })
            this.refresh()
        },
        search_by_plan_repeat (plan_repeat) {
            this.set_state_values({
                page: 1,
                plan_repeat
            })
            this.refresh()
        },
        license_by_id (license_id) {
            return this.entity_record("license", license_id) || {}
        },
        filter_by_host (host_filter) {
            this.set_state_values({
                page: 1,
                host_filter
            })
            this.refresh()
        },
        filter_by_domain_managed (domain_managed) {
            this.set_state_values({
                page: 1,
                domain_managed
            })
            this.refresh()
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        fields () {
            return [
                ...Object.keys(this.columns),
                "client_id",
                "domain",
                "secure_protocol",
                "ip_address",
                "url",
                "computed_error_count",
                "computed_warning_count",
                "ns::monitor;site_id,type,active,computed_checked_at_as_string,next_check_at_as_string,computed_error_count,computed_warning_count,summary",
                "computed_value",
                "computed_lifetime_value",
                "computed_license_revenue",
                "has_broken_elements",
                "ns::license;name"
            ]
        },
        visible_columns () {
            if(this.$mq.match(/^(md|lg|tl|xl|fred)$/i)) {
                const { name, plan_id, license_ids, hosting_cost, computed_lifetime_value } = this.columns
                return {
                    name,
                    plan_id,
                    license_ids,
                    hosting_cost,
                    computed_lifetime_value
                }
            }
            return this.columns
        },
        filterable_plans () {
            return [
                { id: "=$", name: this.translate("Any Paying Plan") },
                ...this.plans
            ]
        },
        statuses () {
            return [
                { id: "errors", name: this.translate("With Errors") },
                { id: "warnings", name: this.translate("With Warnings") },
                { id: "broken-elements", name: this.translate("Broken Links/Images") },
                { id: "no-errors", name: this.translate("No Errors") },
                { id: "orphans", name: this.translate("Orphaned") }
            ]
        }
    },
    data () {
        return {
            entity: "site",
            relation_ids: ["monitor", "plan", "license"],
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                plan_id: { label: "Plan", sort: false },
                license_ids: { label: "License(s)", dort: false },
                hosting_cost: { label: "Host. Costs", sort: null, type: "numeric" },
                computed_lifetime_value: { label: "LTV", sort: null, type: "numeric" },
                last_web_check: { label: "Web check", sort: null, type: "numeric" },
                last_domain_check: { label: "Domain check", sort: null, type: "numeric" },
                last_ssl_check: { label: "SSL check", sort: null, type: "numeric" },
                last_links_check: { label: "Links check", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 50,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                host_filter: "",
                domain_managed: false,
                archived: false,
                plan_id: 0,
                plan_repeat: "",
                search: "",
                total: 0,
                found: 0
            },

            sampling: false
        }
    }
}
</script>

<style lang="scss">
#sites-list {
    .btn-group {
        flex-wrap: nowrap;
    }
    /*
    .nibnut-data-table {
        float: left;
    }
    */
}
</style>
