<template>
    <page
        id="site-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <ul class="breadcrumb mb-4">
                <router-link
                    :to="{ name: 'site.list' }"
                    tag="li"
                    class="breadcrumb-item"
                >
                    <base-link
                        :href="{ name: 'site.list' }"
                    >
                        {{ translate("Sites") }}
                    </base-link>
                </router-link>
                <li
                    v-if="!!edited_record && !!edited_record.id"
                    class="breadcrumb-item"
                >
                    {{ edited_record.name }}
                </li>
            </ul>

            <a
                v-if="!!edited_record && !!edited_record.url"
                :href="edited_record.url"
                target="_blank"
                class="h5"
            >
                {{ edited_record.name }}
            </a>
            <span v-else-if="!!edited_record && !edited_record.url" class="h5">
                {{ edited_record.name }}
            </span>
            <default-button
                v-if="!!edited_record && !!edited_record.name && !edited_record.google_doc_id"
                :waiting="creating_knowledge_document"
                :disabled="creating_knowledge_document"
                :title="translate('Create Knowledge Center Document...')"
                size="sm"
                class="ml-2"
                @click="create_knowledge_document"
            >
                <open-icon glyph="lightbulb" size="lg" />
            </default-button>
            <base-link
                v-else-if="!!edited_record && !!edited_record.name && !!edited_record.google_doc_id"
                ref="knowledge_document_link"
                :href="`https://docs.google.com/document/d/${edited_record.google_doc_id}/edit`"
                :title="translate('View Knowledge Center Document')"
                target="_blank"
                class="btn btn-primary btn-sm ml-2"
            >
                <open-icon glyph="lightbulb" size="lg" />
            </base-link>
            <h6
                v-if="edited_record"
                :class="{ 'bg-warning': !edited_record.client_id, 'p-2': !edited_record.client_id, 'text-center': !edited_record.client_id }"
            >
                <span v-if="!edited_record.client_id">
                    {{ translate("Orphaned Site") }}
                </span>
                <span v-else-if="!!client">
                    {{ translate("Client") }}:
                    <router-link
                        :to="{ name: 'client.edit', params: { id: client.id } }"
                    >
                        {{ client.computed_name }}
                    </router-link>
                </span>
                <span v-if="!!edited_record.plan_id" class="text-normal">
                    &nbsp;&bull;&nbsp;{{ plan_by_id(edited_record.plan_id).name }}
                </span>
            </h6>
        </template>

        <alert-button
            :topic="edited_record"
            @click.prevent.stop="alerts_shown = !alerts_shown"
        />

        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            class="mt-8"
        >
            <div class="columns form-horizontal">
                <div
                    :class="{ 'col-4': !!edited_record.id, 'col-12': !edited_record.id }"
                    class="column col-lg-12"
                >
                    <div class="card full-height">
                        <div class="card-body">
                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_record.name"
                                :required="true"
                                :saving="saving('name')"
                                :error="has_error('name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Name") }}</template>
                            </form-input>

                            <form-input
                                id="domain"
                                name="domain"
                                :value="!!edited_record.path ? `${edited_record.domain}${edited_record.path}` : edited_record.domain"
                                :required="false"
                                :saving="saving('domain')"
                                :error="has_error('domain')"
                                class="multiline mt-2"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ translate("Domain") }}
                                    <default-button
                                        v-if="edited_record.ip_address"
                                        flavor="link"
                                        size="sm"
                                        class="float-right"
                                        @click.prevent.stop="copy_to_clipboard(edited_record.ip_address, 'ip_address')"
                                    >
                                        <open-icon v-if="copied === 'ip_address'" glyph="check-circle" />
                                        <open-icon v-else glyph="clipboard" />
                                        {{ edited_record.ip_address }}
                                    </default-button>
                                </template>
                                <template v-slot:left_addon>
                                    <select
                                        class="form-select"
                                        @change="toggle_protocol"
                                    >
                                        <option :value="false" :selected="!edited_record.secure_protocol">http://</option>
                                        <option :value="true" :selected="!!edited_record.secure_protocol">https://</option>
                                    </select>
                                </template>
                                <template v-slot:right_addon>
                                    <default-button
                                        v-if="!!edited_record.domain"
                                        :waiting="fetching_whois"
                                        @click.prevent="whois"
                                    >
                                        WHOIS
                                    </default-button>
                                    <a
                                        v-if="!!edited_record.domain"
                                        :href="`${edited_record.url}${edited_record.wp_admin_path}`"
                                        target="_blank"
                                        class="btn"
                                    >
                                        <open-icon glyph="sign-in-alt" />
                                    </a>
                                    <a
                                        v-if="!!edited_record.domain"
                                        :href="edited_record.url"
                                        target="_blank"
                                        class="btn"
                                    >
                                        <open-icon glyph="external-link-alt" />
                                    </a>
                                </template>
                            </form-input>

                            <form-toggle-input
                                id="check_website"
                                name="check_website"
                                type="checkbox"
                                :value="check_website"
                                :required="false"
                                :disabled="saving('check_website')"
                                :saving="saving('check_website')"
                                class="mt-6"
                                @input="save"
                            >
                                {{ translate('Monitor Website Status') }}
                                <default-button
                                    v-if="check_website"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="regenerate(web_monitor)"
                                >
                                    <open-icon glyph="sync" :spin="reloading[web_monitor.id]" />
                                </default-button>
                                <default-button
                                    v-if="check_website"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="check_website_options_visible = !check_website_options_visible"
                                >
                                    <open-icon :glyph="check_website_options_visible ? 'sort-up' : 'sort-down'" />
                                </default-button>
                            </form-toggle-input>
                            <div v-if="check_website && check_website_options_visible" class="pl-4 mb-6">
                                <div class="columns">
                                    <div class="column col-6 col-sm-12">
                                        <form-input
                                            id="expected_response_time"
                                            name="expected_response_time"
                                            type="number"
                                            v-model="web_monitor.expected_response_time"
                                            :min="1"
                                            :required="false"
                                            :saving="saving('expected_response_time')"
                                            :error="has_error('expected_response_time')"
                                            @input="save_web_monitor"
                                        >
                                            <template v-slot:label>{{ translate("Load Time") }}</template>
                                            <template v-slot:hint>{{ translate("Max. Time Expected") }}</template>
                                            <template v-slot:right_addon><span class="input-group-addon">{{ translate("ms") }}</span></template>
                                        </form-input>
                                    </div>
                                    <div class="column col-6 col-sm-12">
                                        <form-input
                                            id="notification_trigger"
                                            name="notification_trigger"
                                            type="number"
                                            v-model="web_monitor.notification_trigger"
                                            :min="1"
                                            :required="false"
                                            :saving="saving('notification_trigger')"
                                            :error="has_error('notification_trigger')"
                                            @input="save_web_monitor"
                                        >
                                            <template v-slot:label>{{ translate("Confirm errors") }}</template>
                                            <template v-slot:hint>{{ translate("Notify only if this many checks fail") }}</template>
                                            <template v-slot:right_addon><span class="input-group-addon">{{ translate("times") }}</span></template>
                                        </form-input>
                                    </div>
                                    <div class="column col-12 mt-4">
                                        <form-input
                                            id="content_match"
                                            name="content_match"
                                            v-model="web_monitor.content_match"
                                            :required="false"
                                            :saving="saving('content_match')"
                                            :error="has_error('content_match')"
                                            @input="save_web_monitor"
                                        >
                                            <template v-slot:label>{{ translate("Content Match") }}</template>
                                            <template v-slot:hint>{{ translate("Plain text ('find me') or regexp ('/(find|found)/')") }}</template>
                                        </form-input>
                                    </div>
                                </div>
                            </div>

                            <form-toggle-input
                                id="check_domain"
                                name="check_domain"
                                type="checkbox"
                                :value="check_domain"
                                :required="false"
                                :disabled="saving('check_domain')"
                                :saving="saving('check_domain')"
                                @input="save"
                            >
                                {{ translate('Monitor Domain') }}
                                <span
                                    v-if="!!domain_monitor && !!domain_monitor.expires_on && monitor_expired(domain_monitor)"
                                    class="text-error ml-2"
                                >
                                    {{ translate("Expired on {date}", { date: $moment(domain_monitor.expires_on).format("YYYY-MM-DD") }) }}
                                </span>
                                <span
                                    v-else-if="!!domain_monitor && !!domain_monitor.expires_on"
                                    class="text-gray ml-2"
                                >
                                    {{ translate("Expires on {date}", { date: $moment(domain_monitor.expires_on).format("YYYY-MM-DD") }) }}
                                </span>
                                <default-button
                                    v-if="check_domain"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="regenerate(domain_monitor)"
                                >
                                    <open-icon glyph="sync" :spin="reloading[domain_monitor.id]" />
                                </default-button>
                                <default-button
                                    v-if="check_domain"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="check_domain_options_visible = !check_domain_options_visible"
                                >
                                    <open-icon :glyph="check_domain_options_visible ? 'sort-up' : 'sort-down'" />
                                </default-button>
                            </form-toggle-input>
                            <div v-if="check_domain && check_domain_options_visible" class="pl-4 mb-6">
                                <div class="columns">
                                    <form-toggle-input
                                        id="domain_managed"
                                        name="domain_managed"
                                        type="checkbox"
                                        v-model="edited_record.domain_managed"
                                        :required="false"
                                        :disabled="saving('domain_managed')"
                                        :saving="saving('domain_managed')"
                                        class="column flex-static"
                                        @input="save"
                                    >
                                        {{ translate('Managed') }}
                                    </form-toggle-input>
                                    <form-input
                                        v-if="edited_record.domain_managed"
                                        id="domain_annual_cost"
                                        name="domain_annual_cost"
                                        type="float"
                                        v-model="edited_record.domain_annual_cost"
                                        :min="0"
                                        :required="false"
                                        :saving="saving('domain_annual_cost')"
                                        :error="has_error('domain_annual_cost')"
                                        glyph="dollar-sign"
                                        glyph-position="left"
                                        class="column flex-variable domain_annual_cost"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Cost") }}</template>
                                    </form-input>
                                </div>
                                <form-toggle-input
                                    id="notify_client"
                                    name="notify_client"
                                    type="checkbox"
                                    v-model="domain_monitor.notify_client"
                                    :required="false"
                                    @input="save_domain_monitor"
                                >
                                    {{ translate('Notify Client') }}
                                </form-toggle-input>
                            </div>

                            <form-toggle-input
                                id="check_ssl"
                                name="check_ssl"
                                type="checkbox"
                                :value="check_ssl"
                                :required="false"
                                :disabled="saving('check_ssl')"
                                :saving="saving('check_ssl')"
                                @input="save"
                            >
                                {{ translate('Monitor SSL Certificate') }}
                                <span
                                    v-if="!!ssl_monitor && !!ssl_monitor.expires_on && monitor_expired(ssl_monitor)"
                                    class="text-error ml-2"
                                >
                                    {{ translate("Expired on {date}", { date: $moment(ssl_monitor.expires_on).format("YYYY-MM-DD") }) }}
                                </span>
                                <span
                                    v-else-if="!!ssl_monitor && !!ssl_monitor.expires_on"
                                    class="text-gray ml-2"
                                >
                                    {{ translate("Expires on {date}", { date: $moment(ssl_monitor.expires_on).format("YYYY-MM-DD") }) }}
                                </span>
                                <default-button
                                    v-if="check_ssl"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="regenerate(ssl_monitor)"
                                >
                                    <open-icon glyph="sync" :spin="reloading[ssl_monitor.id]" />
                                </default-button>
                            </form-toggle-input>

                            <form-toggle-input
                                id="check_links"
                                name="check_links"
                                type="checkbox"
                                :value="check_links"
                                :required="false"
                                :disabled="saving('check_links')"
                                :saving="saving('check_links')"
                                class="mb-5"
                                @input="save"
                            >
                                {{ translate('Monitor Broken Links & Images') }}
                                <default-button
                                    v-if="check_links"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="regenerate(links_monitor)"
                                >
                                    <open-icon glyph="sync" :spin="!!links_monitor && reloading[links_monitor.id]" />
                                </default-button>
                                <default-button
                                    v-if="check_links"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="check_links_options_visible = !check_links_options_visible"
                                >
                                    <open-icon :glyph="check_links_options_visible ? 'sort-up' : 'sort-down'" />
                                </default-button>
                            </form-toggle-input>
                            <div v-if="check_links && check_links_options_visible" class="pl-4 mb-4">
                                <form-toggle-input
                                    id="notify_client"
                                    name="notify_client"
                                    type="checkbox"
                                    v-model="links_monitor.notify_client"
                                    :required="false"
                                    @input="save_links_monitor"
                                >
                                    {{ translate('Notify Client') }}
                                </form-toggle-input>
                            </div>
                            <div
                                v-if="!!links_monitor && !!links_monitor.owner_last_notified_at"
                                class="text-small text-gray pl-4 mb-6"
                            >
                                {{ translate("Client was last notified on ") }} {{ links_monitor.owner_last_notified_at | nibnut.date }}
                            </div>

                            <form-toggle-input
                                id="check_site_email"
                                name="check_site_email"
                                type="checkbox"
                                :value="check_site_email"
                                :required="false"
                                :disabled="saving('check_site_email')"
                                :saving="saving('check_site_email')"
                                class="mb-5"
                                @input="save"
                            >
                                {{ translate('Site Email') }}
                                <span
                                    v-if="!!site_email_monitor && !!site_email_monitor.computed_checked_at"
                                    class="text-gray ml-2"
                                >
                                    {{ translate("Last checked on {date}", { date: $moment(site_email_monitor.computed_checked_at).format("YYYY-MM-DD") }) }}
                                </span>
                                <default-button
                                    v-if="check_site_email"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="regenerate(site_email_monitor)"
                                >
                                    <open-icon glyph="sync" :spin="!!links_monitor && reloading[links_monitor.id]" />
                                </default-button>
                                <default-button
                                    v-if="check_site_email"
                                    flavor="link"
                                    size="sm"
                                    class="ml-4"
                                    @click.prevent.stop="check_site_email_options_visible = !check_site_email_options_visible"
                                >
                                    <open-icon :glyph="check_site_email_options_visible ? 'sort-up' : 'sort-down'" />
                                </default-button>
                            </form-toggle-input>
                            <div v-if="check_site_email && check_site_email_options_visible" class="pl-4 mb-4">
                                <form-toggle-input
                                    id="notify_client"
                                    name="notify_client"
                                    type="checkbox"
                                    v-model="site_email_monitor.notify_client"
                                    :required="false"
                                    @input="save_site_email_monitor"
                                >
                                    {{ translate('Notify Client') }}
                                </form-toggle-input>
                            </div>

                            <form-input
                                id="name"
                                name="name"
                                v-model="edited_record.name"
                                :required="true"
                                :saving="saving('name')"
                                :error="has_error('name')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Site Name") }}</template>
                            </form-input>

                            <div class="columns">
                                <div
                                    class="column col-12"
                                >
                                    <form-select
                                        id="client_id"
                                        name="client_id"
                                        v-model="edited_record.client_id"
                                        data-source="client"
                                        :required="true"
                                        :show-all="false"
                                        :saving="saving('client_id')"
                                        :error="has_error('client_id')"
                                        class="mb-2"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Client") }}</template>
                                    </form-select>
                                </div>
                                <div
                                    v-if="contacts.length > 1"
                                    class="column col-12"
                                >
                                    <form-select
                                        id="contact_id"
                                        name="contact_id"
                                        :value="edited_record.contact_id"
                                        :data-source="contacts"
                                        :empty-value="0"
                                        :empty-label="default_contact_option"
                                        label-field="computed_name"
                                        :required="false"
                                        :show-all="true"
                                        :saving="saving('contact_id')"
                                        :error="has_error('contact_id')"
                                        class="mb-8"
                                        @input="save"
                                    >
                                        <template v-slot:label>{{ translate("Contact") }}</template>
                                    </form-select>
                                </div>
                            </div>
                            <form-tag-input
                                id="cc"
                                name="cc"
                                :value="cc"
                                :required="false"
                                data-source="contact"
                                :data-source-additional-data="{ client_id: edited_record.client_id, main_contact_id: edited_record.contact_id }"
                                :ad-hoc="true"
                                :show-all="true"
                                :saving="saving('cc')"
                                :error="has_error('cc')"
                                @input="save_cc"
                                @create="save_cc([...cc, $event], 'cc')"
                            >
                                <template v-slot:label>{{ translate("Cc Site Emails To") }}</template>
                            </form-tag-input>
                            <default-button
                                color="error"
                                size="sm"
                                :block="true"
                                class="mt-4"
                                @click.prevent="confirm_record_delete"
                            >
                                {{ translate("Delete Site") }}
                            </default-button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!!edited_record.id"
                    class="column col-8 col-lg-12 d-flex d-flex-columns"
                >
                    <div class="flex-static">
                        <base-dashboard
                            :site-id="edited_record.id"
                        />
                    </div>
                    <reports
                        v-if="!!edited_record && !!edited_record.id"
                        :site-id="edited_record.id"
                        class="mt-4 flex-static"
                    />
                    <div class="mt-4 flex-variable">
                        <div class="card full-height">
                            <div class="card-header">
                                <div class="card-title h5">
                                    {{ translate("Notes") }}
                                </div>
                            </div>
                            <div class="card-body">
                                <form-editor
                                    id="notes"
                                    name="notes"
                                    v-model="edited_record.notes"
                                    size="lg"
                                    :required="false"
                                    :full-height="false"
                                    :saving="saving('notes')"
                                    :error="has_error('notes')"
                                    class="full-height"
                                    @input="save"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-4">
                <div class="card-body">
                    <div class="columns">
                        <div
                            :class="{ 'col-3': !!edited_record.plan_id, 'col-6': !edited_record.plan_id, 'col-md-12': !!edited_record.plan_id, 'col-md-6': !edited_record.plan_id }"
                            class="column col-md-6 col-sm-12"
                        >
                            <form-select
                                id="plan_id"
                                name="plan_id"
                                v-model="edited_record.plan_id"
                                data-source="site"
                                :required="false"
                                :show-all="true"
                                :saving="saving('plan_id')"
                                :error="has_error('plan_id')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Care Plan") }}</template>
                            </form-select>
                        </div>
                        <div
                            v-if="!!edited_record.plan_id"
                            class="column col-md-6 col-sm-12"
                        >
                            <div
                                v-if="!edited_record.plan_value && !!plan_by_id(edited_record.plan_id).monthly_price && !!plan_by_id(edited_record.plan_id).yearly_price"
                            >
                                <form-select
                                    id="plan_repeat"
                                    name="plan_repeat"
                                    v-model="edited_record.plan_repeat"
                                    :data-source="repeats"
                                    :required="true"
                                    :show-all="true"
                                    :saving="saving('plan_repeat')"
                                    :error="has_error('plan_repeat')"
                                    @input="save"
                                >
                                    <template v-slot:label>
                                        {{ translate("Price") }}
                                        <span
                                            v-if="!!plan_by_id(edited_record.plan_id).monthly_price || !!plan_by_id(edited_record.plan_id).yearly_price"
                                            class="text-small ml-4"
                                        >
                                            <default-button
                                                flavor="link"
                                                size="xs"
                                                @click.prevent="toggle_pricing"
                                            >
                                                <span v-if="edited_record.plan_value === null">({{ translate("Set a custom price") }})</span>
                                                <span v-else>({{ translate("Use the plan's default price") }})</span>
                                            </default-button>
                                        </span>
                                    </template>
                                </form-select>
                            </div>
                            <form-input
                                v-else
                                id="plan_value"
                                name="plan_value"
                                type="float"
                                :value="edited_record.plan_value"
                                :min="0"
                                :required="false"
                                :saving="saving('plan_value')"
                                :error="has_error('plan_value')"
                                :editable="edited_record.plan_value !== null"
                                glyph="dollar-sign"
                                glyph-position="left"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ translate("Price") }}
                                    <span
                                        v-if="!!plan_by_id(edited_record.plan_id).monthly_price || !!plan_by_id(edited_record.plan_id).yearly_price"
                                        class="ml-4"
                                    >
                                        <default-button
                                            flavor="link"
                                            size="xs"
                                            @click.prevent="toggle_pricing"
                                        >
                                            <span v-if="edited_record.plan_value === null">({{ translate("Set a custom price") }})</span>
                                            <span v-else>({{ translate("Use the plan's default price") }})</span>
                                        </default-button>
                                    </span>
                                </template>
                                <template v-slot:empty-value>
                                    <span v-if="!edited_record.displayed_plan_value">{{ translate("Free") }}</span>
                                    <span v-else>{{ edited_record.displayed_plan_value | nibnut.currency }} / {{ repeat_as_unit(edited_record.plan_repeat) }}</span>
                                </template>
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">/</span>
                                    <select
                                        :value="edited_record.plan_repeat"
                                        class="form-select"
                                        @change="save($event.target.value, 'plan_repeat')"
                                    >
                                        <option
                                            v-for="repeat in repeat_units"
                                            :key="repeat.id"
                                            :value="repeat.id"
                                        >
                                            {{ repeat.name }}
                                        </option>
                                    </select>
                                </template>
                            </form-input>
                        </div>
                        <div
                            class="column col-md-6 col-sm-12"
                        >
                            <form-date-input
                                id="plan_ends_on"
                                name="plan_ends_on"
                                v-model="edited_record.plan_ends_on"
                                position="bottom"
                                :required="false"
                                :min="$moment().startOf('day').add(1, 'days')"
                                :saving="saving('plan_ends_on')"
                                :error="has_error('plan_ends_on')"
                                @input="save_date"
                            >
                                <template v-slot:label>{{ translate("Next Invoice on:") }}</template>
                            </form-date-input>
                        </div>
                        <div
                            class="column col-md-6 col-sm-12"
                        >
                            <form-date-input
                                id="life_starts_on"
                                name="life_starts_on"
                                v-model="edited_record.life_starts_on"
                                position="bottom"
                                :required="false"
                                :max="$moment().startOf('day')"
                                :saving="saving('life_starts_on')"
                                :error="has_error('life_starts_on')"
                                @input="save_date"
                            >
                                <template v-slot:label>{{ translate("Care Plan Since:") }}</template>
                            </form-date-input>
                        </div>
                    </div>
                    <div class="columns mt-4">
                        <div class="column col-sm-12">
                            <form-input
                                id="monthly_credits"
                                name="monthly_credits"
                                type="number"
                                :value="edited_record.monthly_credits"
                                :min="0"
                                :required="false"
                                :saving="saving('monthly_credits')"
                                :error="has_error('monthly_credits')"
                                :editable="edited_record.monthly_credits !== null"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ translate("Credits") }}
                                    <span
                                        class="ml-1"
                                    >
                                        <default-button
                                            flavor="link"
                                            size="xs"
                                            @click.prevent="toggle_monthly_credits"
                                        >
                                            <span v-if="edited_record.monthly_credits === null">({{ translate("Set a custom nb of monthly credits") }})</span>
                                            <span v-else>({{ translate("Use the plan's default nb of credits") }})</span>
                                        </default-button>
                                    </span>
                                </template>
                                <template v-slot:empty-value>
                                    {{ edited_record.displayed_monthly_credits | nibnut.number("0,0") }} / {{ translate("month") }}
                                </template>
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">/{{ translate("month") }}</span>
                                </template>
                            </form-input>
                        </div>
                        <div class="column col-sm-12">
                            <form-input
                                id="credits_left"
                                name="credits_left"
                                type="number"
                                :value="edited_record.credits_left"
                                :min="0"
                                :required="false"
                                :saving="saving('credits_left')"
                                :error="has_error('credits_left')"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ translate("Monthly Credits Left") }}
                                </template>
                            </form-input>
                        </div>
                        <div class="column col-sm-12">
                            <form-input
                                id="credits_carry_over_left"
                                name="credits_carry_over_left"
                                type="number"
                                :value="edited_record.credits_carry_over_left"
                                :min="0"
                                :required="false"
                                :saving="saving('credits_carry_over_left')"
                                :error="has_error('credits_carry_over_left')"
                                @input="save"
                            >
                                <template v-slot:label>
                                    {{ translate("Extra Credits Left") }}
                                    <default-button
                                        flavor="link"
                                        size="sm"
                                        class="float-right"
                                        @click.prevent.stop="copy_to_clipboard(buy_credits_page_url, 'buy_credits_page_url')"
                                    >
                                        <open-icon v-if="copied === 'buy_credits_page_url'" glyph="check-circle" />
                                        <open-icon v-else glyph="clipboard" />
                                        {{ translate("Buy Credits Page URL") }}
                                    </default-button>
                                </template>
                            </form-input>
                        </div>
                        <div
                            v-if="edited_record.sibling_sites.length"
                            class="column col-sm-12"
                        >
                            <form-tag-input
                                id="share_credits_with"
                                name="share_credits_with"
                                :value="edited_record.share_credits_with"
                                :required="false"
                                :data-source="edited_record.sibling_sites"
                                :show-all="true"
                                :saving="saving('share_credits_with')"
                                :error="has_error('share_credits_with')"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Share Credits With") }}</template>
                            </form-tag-input>
                        </div>
                    </div>
                    <form-toggle-input
                        v-if="edited_record.plan_id"
                        id="whitelabel_report"
                        name="whitelabel_report"
                        type="checkbox"
                        :value="edited_record.whitelabel_report"
                        :required="false"
                        class="mt-4"
                        @input="save"
                    >
                        {{ translate('White Label Monthly Maintenance Report') }}
                    </form-toggle-input>
                <!--
                    <form-input
                        id="domain"
                        name="domain"
                        v-model="edited_record.domain"
                        :required="true"
                        :saving="saving('domain')"
                        :error="has_error('domain')"
                        class="mt-2"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Domain") }}</template>
                        <template v-slot:left_addon>
                            <select
                                class="form-select"
                                @change="toggle_protocol"
                            >
                                <option :value="false" :selected="!edited_record.secure_protocol">http://</option>
                                <option :value="true" :selected="!!edited_record.secure_protocol">https://</option>
                            </select>
                        </template>
                        <template v-slot:right_addon>
                            <a
                                v-if="!!edited_record.domain"
                                :href="edited_record.url"
                                target="_blank"
                                class="btn"
                            >
                                <open-icon glyph="external-link-alt" />
                            </a>
                        </template>
                    </form-input>
                    //-->

                    <div class="columns">
                        <div class="column col-8 col-md-12 col-sm-12 pt-10">
                            <div class="columns">
                                <div class="column col-12 col-md-6 col-sm-12">
                                    <div
                                        v-if="edited_record.plan_id"
                                        class="columns"
                                    >
                                        <div
                                            :class="{ 'col-6': !!edited_record.ga_account_id && !is_ua_property, 'col-4': is_ua_property, 'col-12': !edited_record.ga_account_id }"
                                            class="column col-md-12 col-sm-12"
                                        >
                                            <default-button
                                                v-if="!edited_record.ga_account_id"
                                                :block="true"
                                                @click="save(setting('default_ga_account_id'), 'ga_account_id')"
                                            >
                                                {{ translate('Connect Google Analytics') }}
                                            </default-button>
                                            <form-select
                                                v-else
                                                id="ga_account_id"
                                                name="ga_account_id"
                                                :value="edited_record.ga_account_id"
                                                data-source="site"
                                                :empty-label="translate('Disconnect GA')"
                                                :empty-value="null"
                                                :show-all="true"
                                                :required="false"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("GA Account") }}</template>
                                            </form-select>
                                        </div>
                                        <div
                                            v-if="!!edited_record.ga_account_id"
                                            :class="{ 'col-6': !!edited_record.ga_account_id && !is_ua_property, 'col-4': is_ua_property }"
                                            class="column col-md-12 col-sm-12"
                                        >
                                            <form-select
                                                id="ga_property_id"
                                                name="ga_property_id"
                                                :value="edited_record.ga_property_id"
                                                data-source="site"
                                                :data-source-additional-data="{ ga_account_id: edited_record.ga_account_id }"
                                                :empty-label="translate('None')"
                                                empty-value=""
                                                :show-all="true"
                                                :required="false"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("GA Property") }}</template>
                                            </form-select>
                                        </div>
                                        <div
                                            v-if="is_ua_property"
                                            class="column col-4 col-md-12 col-sm-12"
                                        >
                                            <form-select
                                                id="ga_view_id"
                                                name="ga_view_id"
                                                :value="edited_record.ga_view_id"
                                                data-source="site"
                                                :data-source-additional-data="{ ga_account_id: edited_record.ga_account_id, ga_property_id: edited_record.ga_property_id }"
                                                :empty-label="translate('None')"
                                                empty-value=""
                                                :show-all="true"
                                                :required="false"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("GA View") }}</template>
                                            </form-select>
                                        </div>
                                        <div
                                            v-if="!!edited_record.ga_account_id && (!edited_record.ga_property_id || (is_ua_property && !edited_record.ga_view_id))"
                                            class="column col-12 text-gray text-small"
                                        >
                                            {{ translate("GA will not appear on reports until you have set the property and view, if needed.") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="column col-12 col-md-6 col-sm-12">
                                    <div class="columns">
                                        <div
                                            :class="{ 'col-6': !!edited_record.host, 'col-12': !edited_record.host }"
                                            class="column col-sm-12"
                                        >
                                            <form-select
                                                id="host"
                                                name="host"
                                                v-model="edited_record.host"
                                                data-source="site"
                                                :required="false"
                                                :ad-hoc="true"
                                                :show-all="true"
                                                empty-value=""
                                                :saving="saving('host')"
                                                :error="has_error('host')"
                                                class="has-button"
                                                @input="save_host"
                                                @create="create_autosuggest_value"
                                            >
                                                <template v-slot:label>{{ translate("Host") }}</template>
                                                <template v-slot:right_addon="{ option }">
                                                    <base-link
                                                        v-if="!!option.url"
                                                        :href="option.url"
                                                        target="_blank"
                                                        class="btn btn-link btn-sm"
                                                    >
                                                        <open-icon glyph="external-link-alt" />
                                                    </base-link>
                                                </template>
                                            </form-select>
                                        </div>
                                        <div
                                            v-if="!!edited_record.host"
                                            class="column col-6 col-sm-12"
                                        >
                                            <form-input
                                                id="hosting_cost"
                                                name="hosting_cost"
                                                type="float"
                                                v-model="edited_record.hosting_cost"
                                                :min="0"
                                                :required="false"
                                                :saving="saving('hosting_cost')"
                                                :error="has_error('hosting_cost')"
                                                glyph="dollar-sign"
                                                glyph-position="left"
                                                @input="save"
                                            >
                                                <template v-slot:label>{{ translate("Cost") }}</template>
                                            </form-input>
                                        </div>
                                    </div>
                                    <form-select
                                        id="dns"
                                        name="dns"
                                        v-model="edited_record.dns"
                                        data-source="site"
                                        :required="false"
                                        :ad-hoc="true"
                                        :show-all="true"
                                        empty-value=""
                                        :saving="saving('dns')"
                                        :error="has_error('dns')"
                                        class="has-button mt-2"
                                        @input="save"
                                        @create="create_autosuggest_value"
                                    >
                                        <template v-slot:label>{{ translate("DNS") }}</template>
                                        <template v-slot:right_addon="{ option }">
                                            <base-link
                                                v-if="!!option.url"
                                                :href="option.url"
                                                target="_blank"
                                                class="btn btn-link btn-sm"
                                            >
                                                <open-icon glyph="external-link-alt" />
                                            </base-link>
                                        </template>
                                    </form-select>

                                    <div class="columns">
                                        <form-select
                                            id="registrar"
                                            name="registrar"
                                            v-model="edited_record.registrar"
                                            data-source="site"
                                            :required="false"
                                            :ad-hoc="true"
                                            :show-all="true"
                                            empty-value=""
                                            :saving="saving('registrar')"
                                            :error="has_error('registrar')"
                                            class="has-button column col-sm-12"
                                            @input="save_registrar"
                                            @create="create_autosuggest_value"
                                            @loaded="registrars_loaded"
                                        >
                                            <template v-slot:label>{{ translate("Registrar") }}</template>
                                            <template v-slot:right_addon="{ option }">
                                                <base-link
                                                    v-if="!!option.url"
                                                    :href="option.url"
                                                    target="_blank"
                                                    class="btn btn-link btn-sm"
                                                >
                                                    <open-icon glyph="external-link-alt" />
                                                </base-link>
                                            </template>
                                        </form-select>
                                        <form-input
                                            v-if="!!current_registrar && !!current_registrar.has_notes"
                                            id="registrar_notes"
                                            name="registrar_notes"
                                            v-model="edited_record.registrar_notes"
                                            :required="false"
                                            :saving="saving('registrar_notes')"
                                            :error="has_error('registrar_notes')"
                                            class="column col-sm-12"
                                            @input="save"
                                        >
                                            <template v-slot:label>{{ translate("Account Owner") }}</template>
                                        </form-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column col-4 col-md-12 col-sm-12">
                            <expandable :expandable="!!$mq.match(/^(xs|sm|md)$/i)" :borderless="true" class="mt-6">
                                <template v-slot:title>
                                    <h6>{{ translate("Site Snapshot") }}</h6>
                                </template>
                                <image-upload-input
                                    v-if="!!edited_record"
                                    :url="edited_record.image_url || ''"
                                    :alt="edited_record.name"
                                    :accepts="upload_accepts('image_url')"
                                    :uploading="!!uploading.image_url"
                                    :uploaded="uploaded.image_url||0"
                                    :error="has_error('image_url')"
                                    @upload="upload_file_list('image_url', $event)"
                                    @clear="confirm_clear_file('image_url')"
                                />
                            </expandable>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns mt-4">
                <div class="column col-md-12">
                    <projects
                        v-if="!!edited_record.id"
                        :site-id="edited_record.id"
                    />
                </div>
                <div class="column col-md-12">
                    <site-license-list
                        v-if="!!edited_record.id"
                        :site-id="edited_record.id"
                    />
                </div>
            </div>
        </base-form>

        <expandable class="mt-10">
            <template v-slot:title>{{ translate("Logs") }}</template>
            <logs
                v-if="!!edited_record && !!edited_record.id"
                :site-id="edited_record.id"
            />
        </expandable>

        <modal-dialog
            v-if="!!edited_record"
            id="whois"
            :show.sync="showing_whois"
            class="whois"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("WHOIS \"{domain}\"", { domain: edited_record.domain }) }}</span>
            </template>

            <div
                v-if="!whois_data"
                class="text-italic text-gray text-center"
            >
                {{ translate("No WHOIS information found...") }}
            </div>
            <div
                v-else
            >
                <ul v-if="whois_lines.length" class="mb-8">
                    <li
                        v-for="line in whois_lines"
                        :key="line.id"
                    >
                        {{ line.label }}: <strong>{{ line.value }}</strong>
                    </li>
                </ul>
                <h6 v-if="whois_data.full">{{ translate("Full WHOIS") }}</h6>
                <code
                    v-if="whois_data.full"
                    v-html="whois_data.full"
                    class="bg-gray"
                ></code>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        @click.prevent="showing_whois = false"
                    >
                        {{ translate("Done") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
        <alerts-dialog
            :show.sync="alerts_shown"
            :topic-type="alert_topic_type"
            :topic-id="alert_topic_id"
        />
   </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import is_record_editor_page from "@/nibnut/mixins/IsRecordEditorPage"
import handles_uploads from "@/nibnut/mixins/HandlesUploads"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import handles_copy_paste from "@/custom/mixins/HandlesCopyPaste"
import alertable from "@/custom/mixins/Alertable"

import {
    BaseDashboard,
    SiteLicenseList,
    Expandable
} from "../../custom/components"
import {
    BaseForm,
    BaseLink,
    FormInput,
    FormTagInput,
    FormToggleInput,
    FormDateInput,
    FormSelect,
    FormEditor,
    ImageUploadInput,
    ModalDialog,
    DefaultButton,
    OpenIcon
} from "../../nibnut/components"
import Projects from "./Projects"
import Logs from "./Logs"
import Reports from "./Reports"

export default {
    name: "SiteEditor",
    mixins: [is_record_editor_page, handles_uploads, misc_utilities, string_utilities, handles_copy_paste, alertable],
    components: {
        BaseDashboard,
        SiteLicenseList,
        Expandable,
        BaseForm,
        BaseLink,
        FormInput,
        FormTagInput,
        FormToggleInput,
        FormDateInput,
        FormSelect,
        FormEditor,
        ImageUploadInput,
        DefaultButton,
        ModalDialog,
        OpenIcon,
        Projects,
        Reports,
        Logs
    },
    methods: {
        monitor_by_type (type) {
            return this.monitors.find(monitor => monitor.type === type)
        },
        repeat_as_unit (repeat) {
            if(repeat === "yearly") return this.translate("year")
            return this.translate("month")
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.translate("Do you really want to delete this site? All the logs and data will also be deleted. There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted (record) {
            this.$router.push({ name: "site.list" })
        },
        toggle_protocol () {
            const data = {
                secure_protocol: !this.edited_record.secure_protocol,
                check_ssl: !this.edited_record.secure_protocol
            }
            this.save_data_for_record_id(this.entity, this.edited_record.id, data)
        },
        regenerate (monitor) {
            this.$set(this.reloading, monitor.id, true)
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "monitor",
                    id: monitor.id,
                    action: "refresh",
                    method: "put"
                }
            ).then(() => {
                if(monitor.type === this.constants("monitor_types", "TYPE_SITE_EMAIL").id) this.$success(this.translate("A test email was sent."))
                else this.$success(this.translate("Monitoring requested: you will be notified by email when the report is ready."))
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.$set(this.reloading, monitor.id, false)
            })
        },
        save_host (value, field, option) {
            if(option.default_cost && !this.edited_record.hosting_cost) this.save_data_for_record_id(this.entity, this.edited_record.id, { [field]: this.standardize_field_save_value(value), hosting_cost: option.default_cost })
            else this.save(value, field)
        },
        registrars_loaded (options) {
            this.current_registrar = options.find(option => option.id === this.edited_record.registrar)
        },
        save_registrar (value, field, option) {
            this.save(value, field)
            this.current_registrar = option
        },
        save_monitor (monitor, value, field) {
            if(monitor) {
                if(monitor[field] !== value) monitor[field] = value
                return this.save_data_for_record_id("monitor", monitor.id, { [field]: this.standardize_field_save_value(value) })
            }
            return Promise.resolve()
        },
        save_web_monitor (value, field) {
            this.save_monitor(this.web_monitor, value, field)
        },
        save_domain_monitor (value, field) {
            this.save_monitor(this.domain_monitor, value, field)
        },
        save_ssl_monitor (value, field) {
            this.save_monitor(this.ssl_monitor, value, field)
        },
        save_links_monitor (value, field) {
            this.save_monitor(this.links_monitor, value, field)
        },
        save_site_email_monitor (value, field) {
            this.save_monitor(this.site_email_monitor, value, field)
        },
        create_autosuggest_value (value, field) {
            this.save(value, field)
        },
        plan_by_id (plan_id) {
            return this.entity_record("plan", plan_id) || {}
        },
        toggle_pricing () {
            if(this.edited_record.plan_value === null) {
                if(this.edited_record.plan_repeat === "yearly") this.edited_record.plan_value = this.plan_by_id(this.edited_record.plan_id).yearly_price
                else this.edited_record.plan_value = this.plan_by_id(this.edited_record.plan_id).monthly_price
            } else this.edited_record.plan_value = null
            this.save(this.edited_record.plan_value, "plan_value")
        },
        toggle_monthly_credits () {
            if(this.edited_record.monthly_credits === null) {
                this.edited_record.monthly_credits = this.plan_by_id(this.edited_record.plan_id).monthly_credits
            } else this.edited_record.monthly_credits = null
            this.save(this.edited_record.monthly_credits, "monthly_credits")
        },
        monitor_expired (monitor) {
            return !!monitor && !!monitor.expires_on && this.$moment(monitor.expires_on).isBefore()
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        whois () {
            this.fetching_whois = true
            this.$store.dispatch("RECORD_ACTION", {
                entity: this.entity,
                id: this.edited_record.id,
                action: "whois",
                passthru: true
            }).then(response => {
                this.whois_data = response
                this.showing_whois = true
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.fetching_whois = false
            })
        },
        save_cc (value, field) {
            this.save(value ? value.join(",") : null, field)
        },
        create_knowledge_document () {
            this.creating_knowledge_document = true
            this.$store.dispatch("RECORD_ACTION", {
                entity: this.entity,
                id: this.edited_record.id,
                action: "create-knowledge-document",
                method: "put"
            }).then(() => {
                setTimeout(() => {
                    if(this.$refs.knowledge_document_link) this.$refs.knowledge_document_link.$el.click()
                }, 150)
                // window.open(this.knowledge_document_url)
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.creating_knowledge_document = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        client () {
            if(!this.edited_record || !this.edited_record.id) return null
            return this.entity_records("client").find(client => client.id === this.edited_record.client_id)
        },
        monitors () {
            if(!this.edited_record || !this.edited_record.id) return []
            return this.entity_records("monitor").filter(monitor => monitor.site_id === this.edited_record.id)
        },
        web_monitor () {
            return this.monitor_by_type(this.constants("monitor_types", "TYPE_WEBSITE").id)
        },
        domain_monitor () {
            return this.monitor_by_type(this.constants("monitor_types", "TYPE_DOMAIN").id)
        },
        ssl_monitor () {
            return this.monitor_by_type(this.constants("monitor_types", "TYPE_SSL").id)
        },
        links_monitor () {
            return this.monitor_by_type(this.constants("monitor_types", "TYPE_LINKS").id)
        },
        site_email_monitor () {
            return this.monitor_by_type(this.constants("monitor_types", "TYPE_SITE_EMAIL").id)
        },
        check_website () {
            const monitor = this.web_monitor
            return !!monitor && !!monitor.active
        },
        check_domain () {
            const monitor = this.domain_monitor
            return !!monitor && !!monitor.active
        },
        check_ssl () {
            const monitor = this.ssl_monitor
            return !!monitor && !!monitor.active
        },
        check_links () {
            const monitor = this.links_monitor
            return !!monitor && !!monitor.active
        },
        check_site_email () {
            const monitor = this.site_email_monitor
            return !!monitor && !!monitor.active
        },
        repeats () {
            if(!this.edited_record || !this.edited_record.plan_id) return []
            const plan = this.plan_by_id(this.edited_record.plan_id)
            if(!plan || (!plan.monthly_price && !plan.yearly_price)) return []

            return [
                { id: "monthly", name: `${this.nibnut_filter("nibnut.currency", [plan.monthly_price])} / ${this.repeat_as_unit("monthly")}` },
                { id: "yearly", name: `${this.nibnut_filter("nibnut.currency", [plan.yearly_price])} / ${this.repeat_as_unit("yearly")}` }
            ]
        },
        repeat_units () {
            return [
                { id: "monthly", name: this.repeat_as_unit("monthly") },
                { id: "yearly", name: this.repeat_as_unit("yearly") }
            ]
        },
        whois_lines () {
            if(this.whois_data && this.whois_data.lines) return Object.values(this.whois_data.lines)
            return []
        },
        is_ua_property () {
            return !!this.edited_record && !!this.edited_record.ga_property_id && !!this.edited_record.ga_property_id.match(/^ua-/i)
        },
        contacts () {
            if(!this.edited_record || !this.edited_record.id) return []
            return orderBy(
                this.entity_records("contact").filter(contact => contact.client_id === this.edited_record.client_id),
                "computed_name",
                "asc"
            )
        },
        default_contact_option () {
            const client = this.client
            const main_contact = this.entity_record("contact", client.main_contact_id)
            if(!main_contact) return this.translate("Client's Main Contact")
            return this.translate("Main Contact ({name})", { name: main_contact.first_name })
        },
        cc () {
            if(!this.edited_record || !this.edited_record.cc) return []
            return this.edited_record.cc.split(",")
        },
        alert_topic_type () {
            return "App\\Site"
        },
        alert_topic_id () {
            return this.edited_record ? this.edited_record.id : 0
        },
        buy_credits_page_url () {
            if(this.edited_record) return `${window.location.protocol}//${window.location.host}/site/${this.edited_record.uuid}/credits`
            return ""
        }
    },
    data () {
        return {
            entity: "site",
            entityName: "Site:::Sites",
            relation_ids: ["monitor", "client", "contact", "plan", "site_report"],
            fields: ["ns::site;fieldset::default", "ns::monitor;fieldset::default", "ns::client;fieldset::site_owner", "ns::site_report;fieldset::list"],

            check_website_options_visible: false,
            check_domain_options_visible: false,
            check_links_options_visible: false,
            check_site_email_options_visible: false,

            reuniting: false,
            fetching_whois: false,
            showing_whois: false,
            whois_data: null,

            reloading: {},

            creating_knowledge_document: false,

            current_registrar: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#site-editor {
    .form-horizontal {
        padding: 0;

        .form-group {
            &.multiline {
                display: block;
            }
            &:not(.multiline) {
                & > label.form-label {
                    flex: 0 0 150px;
                }
                & > .input-group, & > .form-autocomplete {
                    flex: 1 1 auto;
                }
            }
            &.domain_annual_cost {
                & > label.form-label {
                    flex: 0 1 75px;
                }
                & > .input-group {
                    flex: 1 1 1px;
                    &.has-icon-left .form-icon {
                        top: 42%;
                    }
                }
            }
        }
    }
    .input-group .form-select {
        flex: 0 1 1%;
    }
    .full-height,
    .wpe-dashboard .card,
    .nibnut-editor > div:first-of-type {
        height: 100%;
    }
    .nibnut-editor {
        width: 100%;
    }
    a.btn {
        img {
            max-height: 100%;
        }
    }

    .modal.whois {
        .modal-container {
            max-width: 65vw;
        }
    }
    @media (max-width: $size-sm) {
        .column + .column {
            margin-top: $unit-4;
        }
    }
}
</style>
