<template>
    <page
        id="ticket-editor"
        :title="page_title"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title><span></span></template>
        <div class="columns">
            <div class="column col-9 col-md-8 col-sm-12">
                <header class="navbar">
                    <section class="navbar-section">
                        <default-button
                            flavor="link"
                            :title="translate('Go To Folder')"
                            size="lg"
                            @click.prevent.stop="back"
                            class="mr-8"
                        >
                            <open-icon glyph="arrow-left" size="lg" />
                        </default-button>
                        <default-button
                            flavor="link"
                            :title="translate('Reply')"
                            size="lg"
                            @click.prevent.stop="compose = 'message'"
                        >
                            <open-icon glyph="reply" size="lg" />
                        </default-button>
                        <default-button
                            flavor="link"
                            :title="translate('Note')"
                            size="lg"
                            @click.prevent.stop="compose = 'note'"
                        >
                            <open-icon glyph="sticky-note" size="lg" />
                        </default-button>
                        <default-button
                            flavor="link"
                            color="error"
                            :title="translate('Delete')"
                            size="lg"
                            @click.prevent.stop="confirm_record_delete"
                        >
                            <open-icon glyph="trash" size="lg" />
                        </default-button>
                    </section>
                    <section class="navbar-section">
                        <default-button
                            v-if="needs_assignment"
                            color="primary"
                            :title="translate('Assign')"
                            size="lg"
                            @click.prevent.stop="assign"
                        >
                            <open-icon glyph="user" size="lg" />
                            {{ translate("Assign...") }}
                        </default-button>
                        <form-select
                            v-else-if="!!edited_record"
                            id="user_id"
                            name="user_id"
                            :value="edited_record.user_id"
                            data-source="user"
                            :required="true"
                            :show-all="true"
                            class="mr-2 mb-0"
                            @input="save"
                        />
                        <form-select
                            v-if="!!edited_record && !needs_assignment"
                            id="status"
                            name="status"
                            :value="edited_record.status"
                            :data-source="statii"
                            :required="true"
                            :show-all="true"
                            class="ml-2 mb-0"
                            @input="save"
                        />
                    </section>
                </header>
                <h3 class="columns">
                    <div class="column">
                        <form-input
                            id="subject"
                            name="subject"
                            v-model="edited_subject"
                            :required="false"
                            :editable="editing_subject"
                        >
                            <template v-slot:read_only>
                                <div class="columns">
                                    <div class="column">
                                        <label class="form-label disabled-field">
                                            {{ page_title }}
                                        </label>
                                    </div>
                                    <div class="column col-auto">
                                        <default-button
                                            v-if="!!edited_record && !!edited_record.id"
                                            flavor="link"
                                            :title="translate('Edit')"
                                            @click.prevent.stop="subject_edit"
                                        >
                                            <open-icon glyph="pencil-alt" />
                                        </default-button>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:right_addon>
                                <default-button
                                    color="error"
                                    :title="translate('Cancel')"
                                    :disabled="saving('subject')"
                                    @click.prevent.stop="editing_subject = false"
                                >
                                    <open-icon glyph="times" />
                                </default-button>
                                <default-button
                                    color="success"
                                    :title="translate('Save')"
                                    :disabled="saving('subject')"
                                    :waiting="saving('subject')"
                                    loader-size="xs"
                                    @click.prevent.stop="subject_save"
                                >
                                    <open-icon glyph="check" />
                                </default-button>
                            </template>
                        </form-input>
                    </div>
                    <div v-if="!!edited_record && !!edited_record.number" class="column col-auto">#{{ edited_record.number }}</div>
                </h3>

                <message-editor
                    :type.sync="compose"
                    :ticket="edited_record"
                />
                <message
                    v-for="message in messages"
                    :key="message.id"
                    :message="message"
                />
            </div>
            <div v-if="!!edited_record" class="column col-3 col-md-4 col-sm-12">
                <header class="navbar">
                    <section class="navbar-section"></section>
                    <section class="navbar-section">
                        <remote-connection-button
                            v-if="!!edited_record.freescout_id"
                            flavor="link"
                            size="lg"
                            :remote-id="edited_record.freescout_id"
                            remote-service-name="FreeScout"
                            :url="freescout_url(`conversation/${edited_record.freescout_id}`)"
                            :can-disconnect="false"
                            class="freescout-button"
                        >
                            <freescout-logo :active="true" />
                        </remote-connection-button>
                    </section>
                </header>
                <div v-if="!needs_assignment">
                    <h3>{{ translate("Client") }}</h3>
                    <form-select
                        id="site_id"
                        name="site_id"
                        v-model="edited_record.site_id"
                        data-source="site"
                        :data-source-additional-data="{ fields: ['id', 'name'] }"
                        :required="true"
                        :show-all="false"
                        @input="save"
                    >
                        <template v-slot:label>
                            <div class="columns">
                                <div class="column">{{ translate("Site") }}</div>
                                <div class="column col-auto">
                                    <router-link
                                        v-if="!!edited_record.site_id"
                                        :to="{ name: 'site.edit', params: { id: edited_record.site_id } }"
                                    >
                                        <base-link
                                            :href="{ name: 'site.edit', params: { id: edited_record.site_id } }"
                                            class="btn btn-link btn-sm"
                                        >
                                            <open-icon glyph="arrow-circle-right" />
                                        </base-link>
                                    </router-link>
                                </div>
                            </div>
                        </template>
                    </form-select>
                    <div v-if="!!edited_record.computed_contact_name">
                        <router-link
                            v-if="!!edited_record.contact_id"
                            :to="{ name: 'client.edit', params: { id: edited_record.computed_client_id } }"
                        >
                            <base-link
                                :href="{ name: 'client.edit', params: { id: edited_record.computed_client_id } }"
                                class="btn btn-link btn-block"
                            >
                                {{ edited_record.computed_contact_name }}
                            </base-link>
                        </router-link>
                        <label v-else class="form-label text-center">{{ edited_record.computed_contact_name }}</label>
                    </div>
                    <mailto-link
                        v-if="!!edited_record.computed_contact_email"
                        :email="edited_record.computed_contact_email"
                        :copy="true"
                        class="btn btn-link btn-block"
                        @click.stop
                    >
                        <template v-slot:default="{ copied }">
                            <open-icon :glyph="copied ? 'check-circle' : 'envelope'" />
                            {{ edited_record.computed_contact_email }}
                        </template>
                    </mailto-link>
                    <base-link
                        v-if="!!site && !!site.url"
                        :href="site.url"
                        target="_blank"
                        class="btn btn-link btn-block"
                    >
                        {{ site.url }}
                        <open-icon glyph="external-link-alt" />
                    </base-link>

                    <section
                        v-if="!!other_tickets.length"
                        class="mt-8"
                    >
                        <h6>{{ translate("Previous Tickets") }}</h6>
                        <ul>
                            <li
                                v-for="ticket in other_tickets"
                                :key="ticket.id"
                            >
                                <router-link
                                    :to="{ name: 'ticket.edit', params: { folder_id: ticket.folder_id, id: ticket.id } }"
                                    @click.native.stop
                                >
                                    <base-link :href="{ name: 'ticket.edit', params: { folder_id: ticket.folder_id, id: ticket.id } }">
                                        {{ ticket.subject }}
                                    </base-link>
                                </router-link>
                            </li>
                        </ul>
                    </section>

                    <section
                        v-if="!!attachments.length"
                        class="mt-8"
                    >
                        <h6>{{ translate("Attachments") }}</h6>
                        <ul>
                            <li
                                v-for="attachment in attachments"
                                :key="attachment.id"
                            >
                                <base-link :href="attachment.fileUrl" target="message-image-viewer">
                                    {{ attachment.fileName }} ({{ attachment.size | nibnut.filesize }})
                                </base-link>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>

        <modal-dialog
            id="ticket-initial-assignment-dialog"
            :show.sync="assigning"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Assign Ticket") }}</span>
            </template>

            <form-select
                id="assignment_user_id"
                name="assignment_user_id"
                v-model="assignment_user_id"
                data-source="user"
                :required="true"
                :show-all="true"
            >
                <template v-slot:label>{{ translate("Assign To") }}</template>
                <template v-slot:left_addon>
                    <span class="input-group-addon">
                        <open-icon glyph="user" />
                    </span>
                </template>
            </form-select>
            <form-select
                id="assignment_site_id"
                name="assignment_site_id"
                v-model="assignment_site_id"
                data-source="site"
                :data-source-additional-data="{ fields: ['id', 'name'] }"
                :required="true"
            >
                <template v-slot:label>{{ translate("Site") }}</template>
            </form-select>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="!!saving_assignment"
                        class="mr-2"
                        @click.prevent="assigning = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :disabled="!!saving_assignment && !!assignment_user_id && !!assignment_site_id"
                        :waiting="!!saving_assignment"
                        class="ml-2"
                        @click.prevent="assignment_save"
                    >
                        {{ $root.translate("Assign") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            :waiting="deleting_record"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import orderBy from "lodash/orderBy"
import { is_record_editor_page } from "@/nibnut/mixins"

import {
    ModalDialog,
    FormInput,
    FormSelect,
    MailtoLink,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    FreescoutLogo,
    RemoteConnectionButton
} from "@/custom/components"
import Message from "./Message"
import MessageEditor from "./MessageEditor"

export default {
    name: "TicketEdit",
    mixins: [is_record_editor_page],
    components: {
        ModalDialog,
        FormInput,
        FormSelect,
        MailtoLink,
        BaseLink,
        DefaultButton,
        OpenIcon,
        FreescoutLogo,
        RemoteConnectionButton,
        Message,
        MessageEditor
    },
    methods: {
        back () {
            this.$router.push({ name: `${this.entity}.list`, params: { folder: this.$route.params.folder } })
        },
        subject_edit () {
            this.edited_subject = this.edited_record.subject
            this.editing_subject = true
        },
        subject_save () {
            this.save(this.edited_subject, "subject").then(() => {
                this.editing_subject = false
            })
        },
        freescout_url (path) {
            if(!this.app_context) return ""
            return `https://${this.app_context.settings.freescout_domain}/${path}`
        },
        assign () {
            this.assignment_user_id = 0
            this.assignment_site_id = 0
            this.assigning = true
        },
        assignment_save () {
            this.save_data_for_record_id(
                this.entity,
                this.edited_record.id,
                {
                    user_id: this.assignment_user_id,
                    site_id: this.assignment_site_id
                }
            ).then(() => {
                this.assigning = false
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete \"{name}\"?", { name: this.edited_record.subject }),
                    message: this.$root.translate("Do you really want to delete this ticket? It will also be delete on Freescout, and there is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.replace({ name: "ticket.list", params: { folder: this.$route.params.folder } })
        }
    },
    computed: {
        page_title () {
            const ticket = this.edited_record
            if(ticket) return ticket.subject || `Ticket #${ticket.number}`
            return this.translate("Ticket")
        },
        needs_assignment () {
            return !!this.edited_record && (!this.edited_record.assignee_id || !this.edited_record.site_id)
        },
        statii () {
            return Object.values(this.constants("ticket_statii"))
        },
        messages () {
            const ticket = this.edited_record
            if(ticket) return ticket.threads
            return []
        },
        attachments () {
            let attachments = []
            this.messages.forEach(message => {
                if(!!message._embedded && !!message._embedded.attachments && !!message._embedded.attachments.length) attachments = attachments.concat(message._embedded.attachments)
            })
            return attachments
        },
        other_tickets () {
            return orderBy(
                this.entity_records("ticket").filter(ticket => {
                    return (ticket.id !== this.edited_record.id) && (ticket.freescout_customer_id === this.edited_record.freescout_customer_id)
                }),
                "last_interaction_at",
                "desc"
            )
        },
        site () {
            if(this.edited_record) return this.entity_record("site", this.edited_record.site_id) || {}
            return {}
        }
    },
    data () {
        return {
            entity: "ticket",
            entityName: "Ticket:::Tickets",
            relation_ids: ["contact", "site", "ticket"],

            edited_subject: "",
            editing_subject: false,

            assignment_user_id: 0,
            assignment_site_id: 0,
            assigning: false,
            saving_assignment: false,

            compose: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#ticket-editor {
    header.navbar {
        margin-bottom: $layout-spacing-lg;
    }
    label[for="site_id"] > sup {
        display: none;
    }
}
</style>
