<template>
    <page
        id="dashboard"
        :title="translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                <span>
                    {{ translate('Dashboard') }}
                </span>
                <stat-button
                    :count="errors_warnings.errors.count"
                    :label="translate('Errors')"
                    color="error"
                    glyph="bomb"
                    class="ml-2"
                    @click.prevent="errors_warnings.errors.refresh++"
                />
                <stat-button
                    :count="errors_warnings.warnings.count"
                    :label="translate('Warnings')"
                    color="warning"
                    glyph="exclamation-triangle"
                    class="ml-2"
                    @click.prevent="errors_warnings.warnings.refresh++"
                />
                <stat-button
                    v-if="errors_warnings.links.count"
                    :count="errors_warnings.links.count"
                    :label="translate('Broken Links')"
                    color="secondary"
                    glyph="unlink"
                    class="ml-2"
                    @click.prevent="errors_warnings.links.refresh++"
                />
                <stat-button
                    v-if="errors_warnings.images.count"
                    :count="errors_warnings.images.count"
                    :label="translate('Missing Images')"
                    color="secondary"
                    glyph="image"
                    class="ml-2"
                    @click.prevent="errors_warnings.images.refresh++"
                />
            </h1>
        </template>

        <list-stats
            v-if="is_at_least_administrator"
            entity="app"
            :stat-ids="[
                'total-paid-plans',
                'total-clients',
                'total-projects',
                'total-sites',

                'avg-revenue',
                'total-plan-revenue',
                'total-active-projects-value',
                'total-income',

                'total-license-revenue',
                'total-license-cost',
                'total-hosting-costs',
                'total-expense'
            ]"
            :date-range="current_date_range"
        >
            <template
                v-slot:default="{ id, waiting, data }"
            >
                <div
                    :class="{ clickable: card_is_clickable(id) }"
                    class="card"
                    @click.prevent.stop="show_card_detail(id, data)"
                >
                    <div class="card-header">
                        <div class="card-title h3">
                            <loader v-if="waiting" size="sm" />
                            <span v-else-if="id === 'total-clients'">{{ data[id] | nibnut.number("0,0") }}</span>
                            <span v-else-if="id === 'total-sites'">{{ data[id] | nibnut.number("0,0") }}</span>
                            <span v-else-if="id === 'total-license-cost'">{{ data['total-license-monthly-cost'] | nibnut.currency }}</span>
                            <span v-else-if="id === 'avg-revenue'">{{ data['total-plan-revenue'] / data['total-paid-plans'] | nibnut.currency }}</span>
                            <span v-else-if="id === 'total-paid-plans'">{{ data[id] | nibnut.number("0,0") }}</span>
                            <span v-else-if="id === 'total-projects'">{{ data[id] | nibnut.number("0,0") }}</span>
                            <span v-else-if="id === 'total-active-projects-value'">{{ data[id] | nibnut.currency }}</span>
                            <span v-else-if="id === 'total-income'">{{ (data['total-plan-revenue'] + data['total-license-revenue'] + data['total-active-projects-value']) | nibnut.currency }}</span>
                            <span v-else-if="id === 'total-expense'">{{ (data['total-license-monthly-cost'] + data['total-hosting-costs']) | nibnut.currency }}</span>
                            <span v-else>{{ data[id] | nibnut.currency }}</span>
                        </div>
                        <div class="card-subtitle">
                            <router-link
                                v-if="id === 'total-clients'"
                                :id="card_link_id(id)"
                                :to="{ name: 'client.list' }"
                                @click.native.stop
                            >
                                {{ translate("Clients") }}
                            </router-link>
                            <router-link
                                v-else-if="id === 'total-sites'"
                                :id="card_link_id(id)"
                                :to="{ name: 'site.list' }"
                                @click.native.stop
                            >
                                {{ translate("Sites") }}
                            </router-link>
                            <router-link
                                v-else-if="id === 'total-license-cost'"
                                :id="card_link_id(id)"
                                :to="{ name: 'license.list' }"
                                @click.native.stop
                            >
                                {{ translate("License Costs") }}<span class="text-small text-gray"> / month</span>
                            </router-link>
                            <span v-else-if="id === 'avg-revenue'">{{ translate("Avg. Revenue") }}<span class="text-small text-gray"> / client</span></span>
                            <span v-else-if="id === 'total-paid-plans'">
                                {{ translate("Care Plans") }}
                                <span class="text-small text-gray">({{ current_date_range.from | nibnut.date((date_range_id === "custom") ? "DD/MM/YY" : "MM/YY") }} - {{ current_date_range.to | nibnut.date((date_range_id === "custom") ? "DD/MM/YY" : "MM/YY") }})</span>
                            </span>
                            <span v-else-if="id === 'total-plan-revenue'">{{ translate("Maint. Revenue") }}<span class="text-small text-gray"> / month</span></span>
                            <router-link
                                v-else-if="id === 'total-license-revenue'"
                                :id="card_link_id(id)"
                                :to="{ name: 'license.list' }"
                                @click.native.stop
                            >
                                {{ translate("License Revenue") }}<span class="text-small text-gray"> / month</span>
                            </router-link>
                            <span v-else-if="id === 'total-hosting-costs'">{{ translate("Hosting Costs") }}<span class="text-small text-gray"> / month</span></span>
                            <router-link
                                v-else-if="id === 'total-projects'"
                                :id="card_link_id(id)"
                                :to="{ name: 'project.list' }"
                                @click.native.stop
                            >
                                {{ translate("12-months Projects") }}
                            </router-link>
                            <router-link
                                v-else-if="id === 'total-active-projects-value'"
                                :id="card_link_id(id)"
                                :to="{ name: 'project.list' }"
                                @click.native.stop
                            >
                                {{ translate("Projects Revenue") }}<span class="text-small text-gray"> / month</span>
                            </router-link>
                            <span v-else-if="id === 'total-income'">{{ translate("Total Revenue") }}<span class="text-small text-gray"> / month</span></span>
                            <span v-else-if="id === 'total-expense'">{{ translate("Total Costs") }}</span>
                            <span v-else>{{ id }}</span>
                        </div>
                        <div v-if="!waiting" class="text-small text-gray">
                            <span v-if="id === 'total-clients'">{{ data['lifetime-value'] | nibnut.currency }} {{ translate("LTV") }}</span>
                            <span v-else-if="id === 'total-projects'">{{ data['total-projects-value'] | nibnut.currency }} {{ translate("LTV") }}</span>
                            <span v-else-if="id === 'total-active-projects-value'">{{ data['total-active-projects'] | nibnut.number("0,0") }} {{ translate("Active Projects") }}</span>
                            <span v-else-if="id === 'total-license-cost'">{{ data[id] | nibnut.currency }} {{ translate("Total License Costs") }}</span>
                            <span v-else-if="id === 'avg-revenue'">{{ (data['total-plan-revenue'] / data['total-paid-plans']) * 12 | nibnut.currency }} / year</span>
                            <div v-else-if="(id === 'total-paid-plans')">
                                <apexchart
                                    id="care-plans"
                                    type="line"
                                    :options="chart_options('care-plans', data['paid-plans-count'])"
                                    :series="chart_data('care-plans', data['paid-plans-count'])"
                                />
                            </div>
                            <span v-else-if="id === 'total-sites'">{{ data['total-managed-domains'] | nibnut.number("0,0") }} domains &bull; {{ data['total-domain-annual-costs'] | nibnut.currency }} / year</span>
                            <span v-else-if="(id === 'total-paid-plans') || (id === 'total-sites') || (id === 'total-income') || (id === 'total-expense')" class="d-invisible">{{ id }}</span>
                            <span v-else>{{ data[id] * 12 | nibnut.currency }} / year</span>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div
                            :class="{ 'd-invisible': id !== 'total-paid-plans' }"
                            class="btn-group btn-group-block"
                        >
                            <default-button
                                v-for="date_range in date_ranges"
                                :key="date_range.id"
                                size="xs"
                                :active="date_range.id === date_range_id"
                                :disabled="(date_range.id === date_range_id) && (date_range.id !== 'custom')"
                                @click.prevent.stop="pick_date_range(date_range.id)"
                            >
                                {{ date_range.label }}
                            </default-button>
                        </div>
                    </div>
                </div>
            </template>
        </list-stats>

        <base-dashboard
            v-if="is_at_least_administrator"
            :site-id="0"
            title=""
            :show-errors="errors_warnings.errors.refresh"
            :show-warnings="errors_warnings.warnings.refresh"
            :show-links="errors_warnings.links.refresh"
            :show-images="errors_warnings.images.refresh"
            @loading="stop_waiting"
            @loaded="refresh_errors_warnings"
        />

        <modal-dialog
            id="graph-viewer"
            :show.sync="viewing"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ translate("Maintenance Plans") }}
                </span>
            </template>

            <apexchart
                v-if="viewing && viewing_data"
                id="care-plans-full"
                type="line"
                :options="chart_options('care-plans-full', viewing_data['paid-plans-count'])"
                :series="chart_data('care-plans-full', viewing_data['paid-plans-count'])"
            />
        </modal-dialog>

        <modal-dialog
            id="custom-date-range-picker"
            :show.sync="customizing_date_range"
        >
            <template v-slot:title>
                <span class="h5">
                    {{ translate("Custom Date Range") }}
                </span>
            </template>

            <div class="my-16">
                <form-date-input
                    id="from"
                    name="from"
                    v-model="custom_date_range.from"
                    position="over"
                    :required="true"
                    :placeholder="translate('From')"
                    calendar-size="sm"
                >
                    <template v-slot:label>{{ translate("From") }}</template>
                </form-date-input>
                <form-date-input
                    id="to"
                    name="to"
                    v-model="custom_date_range.to"
                    position="over"
                    :required="true"
                    :placeholder="translate('To')"
                    calendar-size="sm"
                >
                    <template v-slot:label>{{ translate("To") }}</template>
                </form-date-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="customizing_date_range = false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="set_custom_date_range"
                    >
                        {{ $root.translate("Set") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </page>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import { is_page, profile_utilities } from "@/nibnut/mixins"

import { ModalDialog, FormDateInput, DefaultButton } from "@/nibnut/components"
import { Loader, ListStats, BaseDashboard, StatButton } from "@/custom/components"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "Dashboard",
    mixins: [is_page, profile_utilities],
    components: {
        ModalDialog,
        FormDateInput,
        DefaultButton,
        Loader,
        ListStats,
        BaseDashboard,
        StatButton,
        Apexchart: VueApexCharts
    },
    mounted () {
        this.start_waiting()
    },
    watch: {
        profile_id: "start_waiting",
        $route: "start_waiting"
    },
    methods: {
        statused (status) {
            this.page_status = status
        },
        start_waiting () {
            if(this.profile_id && !this.is_at_least_administrator) this.$router.replace({ name: "license.list" })
            this.waiting = true
        },
        stop_waiting (waiting) {
            if(!waiting) this.waiting = waiting
        },
        refresh_errors_warnings ({ errors, warnings, links, images }) {
            this.errors_warnings.errors.count = errors
            this.errors_warnings.warnings.count = warnings
            this.errors_warnings.links.count = links
            this.errors_warnings.images.count = images
        },
        card_is_clickable (card_id) {
            return ["total-paid-plans", "total-clients", "total-projects", "total-sites", "total-active-projects-value", "total-license-revenue", "total-license-cost"].indexOf(card_id) >= 0
        },
        card_link_id (card_id) {
            return `card-navigation-${card_id}`
        },
        show_card_detail (card_id, data) {
            if(!this.card_is_clickable(card_id)) return
            const node = document.getElementById(this.card_link_id(card_id))
            if(node) node.click()
            else {
                this.viewing_data = data
                this.viewing = true
            }
        },
        raw_chart_data (chart_id, data) {
            return data
        },
        chart_options (chart_id, data) {
            const color = "#00bcb4"
            const categories = Object.keys(this.raw_chart_data(chart_id, data)).map(date => this.$moment(date).format("MMM YYYY"))
            return {
                chart: {
                    id: chart_id,
                    height: 20,
                    sparkline: {
                        enabled: (chart_id === "care-plans")
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: [color],
                stroke: {
                    curve: "smooth",
                    width: 1,
                    lineCap: "round"
                },
                xaxis: {
                    categories
                }
            }
        },
        chart_data (chart_id, data) {
            return [
                {
                    name: this.translate("Care plans"),
                    data: Object.values(this.raw_chart_data(chart_id, data))
                }
            ]
        },
        pick_date_range (date_range_id) {
            if(date_range_id !== "custom") this.date_range_id = date_range_id
            else {
                const current_date_range = this.current_date_range
                this.custom_date_range.from = current_date_range.from
                this.custom_date_range.to = current_date_range.to
                this.customizing_date_range = true
            }
        },
        set_custom_date_range () {
            const current_date_range = this.date_ranges.custom
            current_date_range.from = this.$moment(this.custom_date_range.from)
            current_date_range.to = this.$moment(this.custom_date_range.to)
            this.date_range_id = "custom"
            this.customizing_date_range = false
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        date_ranges () {
            const to = this.$moment().endOf("month")
            return {
                custom: { id: "custom", label: this.translate("Custom"), from: null, to: null },
                "5y": { id: "5y", label: this.translate("5 Years"), from: this.$moment().startOf("month").subtract(5, "years"), to },
                "3y": { id: "3y", label: this.translate("3 Years"), from: this.$moment().startOf("month").subtract(3, "years"), to },
                "2y": { id: "2y", label: this.translate("2 Years"), from: this.$moment().startOf("month").subtract(2, "years"), to },
                "1y": { id: "1y", label: this.translate("1 Year"), from: this.$moment().startOf("month").subtract(1, "year"), to }
            }
        },
        current_date_range () {
            return this.date_ranges[this.date_range_id]
        }
    },
    data () {
        return {
            bootstraped: false,
            errors_warnings: {
                errors: { count: 0, refresh: 0 },
                warnings: { count: 0, refresh: 0 },
                links: { count: 0, refresh: 0 },
                images: { count: 0, refresh: 0 }
            },
            show_errors: 0,
            warnings_count: 0,
            show_warnings: 0,
            viewing: false,
            viewing_data: null,
            date_range_id: "1y",
            custom_date_range: { from: null, to: null },
            customizing_date_range: false
        }
    }
}
</script>

<style lang="scss">
#dashboard {
    .status-badge {
        display: inline-block;
        position: relative;
        top: -0.2rem;
        border-radius: 50%;
        padding: 0 0.25em;
        font-size: 1rem;
    }
    .card-header {
        position: relative;
        & > .card-subtitle a {
            color: inherit;
        }
        & > .text-small.text-gray {
            min-height: 1rem;

            & > div > div {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
            }
        }
    }
    .card-footer {
        padding: 0;
    }
}
</style>
