<template>
    <div class="header">
        <header class="navbar">
            <section class="navbar-section">
                <default-button
                    v-if="!is_navigation_forced"
                    flavor="link"
                    color="light"
                    class="mr-4"
                    @click.prevent="$emit('navigate', null)"
                >
                    <open-icon glyph="bars" />
                </default-button>
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && (!!profile_id || isPublicPage)"
                    flavor="link"
                    color="light"
                    @click.prevent="bug_report"
                >
                    <open-icon glyph="bug" />
                </default-button>
            </section>
            <section class="navbar-center">
                <base-link
                    v-if="!!$route.meta && (!!$route.meta.login_required || !!$route.meta.admin_route || !!$route.meta.licensing_route)"
                    :href="{ name: 'home' }"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
                <app-logo v-else />
                <span v-if="!!setting('env')" class="env-marker ml-4">{{ setting('env') }}</span>
            </section>
            <section class="navbar-section">
                <master-search-box
                    v-if="is_at_least_administrator"
                    :class="{ 'mr-4': !$mq.match(/^(xs|sm|md)$/i) }"
                />
                <default-button
                    v-if="!!profile_id"
                    flavor="link"
                    color="light"
                    :title="$root.translate('Logout')"
                    @click.prevent="logout"
                >
                    <open-icon glyph="sign-out-alt" />
                </default-button>
                <default-button
                    v-else-if="!profile_id"
                    flavor="link"
                    :title="$root.translate('Login/Signup')"
                    color="light"
                    @click.prevent="login"
                >
                    <open-icon glyph="sign-in-alt" />
                </default-button>
                <base-link
                    :href="{ name: 'profile' }"
                    class="btn btn-link"
                >
                    <figure class="avatar avatar-sm">
                        <open-icon v-if="!profile_id||(!!profile&&!profile.avatar)" glyph="user" size="2x" />
                        <img v-else :src="profile.avatar" :alt="profile.name">
                    </figure>
                </base-link>
            </section>
        </header>
    </div>
</template>

<script>
import { profile_utilities, ui_utilities } from "./nibnut/mixins"

import { AppLogo, DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import { MasterSearchBox } from "@/custom/components"

export default {
    name: "AppHeader",
    mixins: [profile_utilities, ui_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        OpenIcon,
        MasterSearchBox
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "./assets/sass/variables";

.header {
    .navbar-section:first-child {
        padding-left: 0.3rem;
    }
    .btn {
        border: 0;

        i {
            font-size: 1rem;
        }
    }
    .env-marker {
        font-size: 0.8em;
        left: $unit-4;
        top: $unit-4;
        padding: $unit-1 $unit-2;
        border-radius: 0.5em;
        background-color: fuchsia;
        color: white;
    }
}
</style>
