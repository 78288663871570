<template>
    <div class="columns list-stats">
        <div
            v-for="stat_id in statIds"
            :key="stat_id"
            class="column col-3 col-lg-4  col-md-6 col-sm-12"
        >
            <slot
                :id="stat_id"
                :waiting="loading"
                :data="stats"
            />
        </div>
    </div>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import handles_errors from "@/nibnut/mixins/HandlesErrors"

export default {
    name: "ListStats",
    mixins: [profile_utilities, handles_errors],
    mounted () {
        this.load()
    },
    watch: {
        entity: "load",
        $route: "load",
        profile_id: "load",
        "dateRange.from": "load",
        "dateRange.to": "load"
    },
    methods: {
        load () {
            if(this.profile_id && this.is_at_least_administrator && this.entity) {
                this.loading = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: this.entity,
                        action: "list-stats",
                        data: { from: this.dateRange.from ? this.dateRange.from.format("YYYY-MM-DD") : null, to: this.dateRange.to ? this.dateRange.to.format("YYYY-MM-DD") : null },
                        method: "get",
                        passthru: true
                    }
                ).then(stats => {
                    this.stats = stats
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }
        }
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        statIds: {
            type: Array,
            required: true
        },
        dateRange: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: true,
            stats: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.list-stats {
    margin: 0 0 $unit-8 0;
    padding: 0;
    text-align: center;
    justify-content: center;

    & > .column {
        padding: $unit-2;

        .card {
            background-color: $list-stat-bg-color;
            border-radius: 0.35rem;
            border: 0;
        }
    }
}
</style>
